<template>
  <div class="dia-content">

    <el-form ref="form" :model="form" label-width="150px">
      <el-form-item label="驾校名称">
        <span>{{ form.schoolName }}</span>
      </el-form-item>
      <el-form-item label="选择充值名额类型">
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="输入名额数量">
        <el-input v-model="form.nums" placeholder="请输入名额数量"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">增加</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: {},
    },

  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.form.id = val.id
        this.form.schoolName = val.schoolName
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      options: [{
        value: 1,
        label: '小车(C1 C2 C5)'
      }, {
        value: 2,
        label: '大车(A2 B2)'
      }, {
        value: 3,
        label: '从业资格证'
      }],
      value: '',
      form: {
        id: 0,
        schoolName: '',
        nums: ''
      }

    }
  },
  methods: {
    onSubmit () {
      if (this.value < 1) {
        this.$message('选择充值名额类型')
        return
      }


      const data = {
        id: this.form.id,
        nums: this.form.nums,
        type: this.value
      }
      this.value = ''
      this.form.nums = ''
      this.$emit("addNum", data)
    },

  },
};
</script>
<style lang="less" scpoed>
.dia-content {
  padding: 50px;

  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
</style>
